$primary-blue: #399bce;
$primary-grey: #c8c8c8;
$error-red: #da0000;
$nav-height: 56px;

body {
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 60px;
	background-color: #fafafa;
	min-width: 320px;
	margin: 0;
	font-weight: 400;
    line-height: 1.5;
    color: #212529;
	text-align: left;
	&.active.no-outline *:focus {
		outline: none;
	}
}
*, ::after, ::before {
    box-sizing: border-box;
}
.off-screen {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
}
.active #root {
	background-color: #fff;
}
button {
    text-align: center;
    cursor: pointer;
	color: #000;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0;
	margin: 0;
    border: 0;
	border-image: initial;
	font-family: 'Montserrat', sans-serif;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
	border-radius: .25rem;
	text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-small {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .3rem;
    font-size: 0.8rem;
    line-height: 1.5;
	border-radius: .25rem;
	text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-close {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0;
    font-size: 1.3rem;
    line-height: 1;
	text-decoration: none;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	color: #000;
	position: absolute;
	top: 30px;
	right: 30px;
}
.nav {
	background-color: #000;
	color: #fff;
	margin-bottom: 40px;
}
footer {
	font-size: 12px;
	background-color: #fafafa;
	margin-top: 100px;
}
footer .content-container {
	padding: 40px 0 100px 0;
}
#root.active {
	min-height: 300px;
	background-color: #fff;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
h1 {
	text-align: center;
	margin: 0;
	font-weight: 400;
	padding: 0 20px;
}
h2 {
	margin: 0 0 10px 0;
	font-weight: 500;
	font-size: 26px;
}
.nav {
	min-height: $nav-height;
	display: flex;
    flex-direction: column;
    justify-content: center;
	.container-padding {
		display: flex;
		align-items: center;
	}
	a, a:hover, a:active, a:visited, a:link {
		color: #fff;
		text-decoration: none;
	}
	.title {
		flex: 1;
		text-align: left;
	}
	.date {
		text-align: right;
		display: flex;
		align-items: center;
		&:after {
			content: '';
			width: 0; 
			height: 0; 
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 7px solid #fff;
			margin-left: -15px;
			pointer-events: none;
		}
		select {
			color: #fff;
			padding: 20px 30px 20px 15px;
			font-family: 'Montserrat', sans-serif;
			option {
				color: #000;
			}
		}
	}
	.log-in-out,
	.log-in-out:hover,
	.log-in-out:active,
	.log-in-out:visited,
	.log-in-out:link {
		color: #fff;
		background-color: $primary-blue;
		margin-left: 20px;
		font-size: .9rem;
		padding: 5px 10px;
		border-radius: 10px;
	}
	.log-in-out:hover {
		background-color: #3fa9e2;
	}
}
.no-style {
    -webkit-appearance: none;
    background-color: initial;
    border: 0;
    cursor: pointer;
}
.content-container {
	max-width: 970px;
	width: 100%;
	margin: 0 auto;
}
.instructions {
	text-align: center;
	margin-bottom: 30px;
	p {
		margin-bottom: 5px;
	}
}
.menu-items {
	&.desktop {
		margin-top: 20px;
	}
	th>div {
		transform: rotate(305deg);
	}
}
.menu-options {
	display: flex;
	flex-wrap: wrap;
	max-width: 700px;
	margin: 0 auto 100px auto;
	&.open {
		margin: 0 auto 40px auto;
	}
	&>button {
		flex: 0 0 50%;
		color: white;
		text-decoration: none;
		padding: 5px;
	}
	&>button:hover {
		transform: scale(1.03);
		transition: transform 0.7s;
	}
	&>button:last-child {
		margin-right: 0;
	}
	&>button:first-child {
		margin-left: 0;
	}
	h2 {
		text-shadow: 3px 2px 8px #000;
		margin: 0;
		font-size: 32px;
	}
}
.menu-category {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 175px;
	background-size: cover;
	padding: 0 10px;
}
table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
	background-color: transparent;
	&.mobile {
		tr {
			td:nth-child(3) {
				width: auto;
			}
		}
		td:first-child,
		td:nth-child(2) {
			vertical-align: top;
		}
	}
	&.table-striped {
		tr:nth-of-type(odd) {
			background-color: #fff;
		}
		tbody:nth-of-type(odd) tr {
			background-color: #fafafa;
		}
	} 
	th:last-child>div {
		max-width: 95px;
		padding-left: 25px;
		margin: -32px;
	}
}
.menu-table {
	tr td {
		width: 58px;
	}
}
.table {
	td, th {
		padding: .75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
		vertical-align: middle;
	}
	th {
		border-bottom: 0;
		border-top: 0;
	}
	tr {
		&.menu-break {
			background-color: #399bce!important;
			td,
			td:last-child,
			th,
			th:last-child {
				background-color: $primary-blue;
				color: #fff;
				font-weight: 600;
				padding: 5px .75rem;
				border-top: 1px solid $primary-blue;
				border-bottom: 1px solid $primary-blue;
			}
			button {
				color: #fff;
				font-size: 1rem;
				font-weight: 600;
				width: 100%;
				position: relative;
				text-align: right;
			}
			&:hover {
				opacity: 1;
				cursor: initial;
			}
			.saved-meals-col {
				//width: 40%;
				button {
					text-align: left;
				}
			}
		}
	}
	&.desktop {
		th:last-child,
		tr td:last-child {
			padding: .75rem 1.5rem;
			text-align: left;
			width: 85px;
		}
		.totals-label td {
			vertical-align: bottom;
		}
		th {
			height: 150px;
			vertical-align: bottom;
		}
		td {
			vertical-align: middle;
			text-align: center;
		}
	}
	tbody+tbody {
		border-top: 0;
	}
	&.saved-meals-table {
		margin-top: 40px;
		.meal-options {
			padding: 0.75rem 10px 0.75rem 0;
			width: 95px;
		}
	}
	
}
.totals-label td>div {
	transform: rotate(270deg);
}
.menu-items th>div,
.totals-label td>div {
    white-space: nowrap;
    text-align: left;
    padding-left: 10px;
    max-width: 32px;
}
.menu-items.desktop th,
.totals-label {
	height: 160px;
}
.table-responsive {
    border: 0;
}


.total-nutrition {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: white;
	margin-bottom: 0;
	border-top: 1px solid #dee2e6;
	overflow: auto;
	max-height: 100vh;
	td {
		padding: 20px 0.75em;
		font-weight: bold;
		font-size:120%;
		border: 0;
	}
	table {
		margin: 0;
	}
	tr {
		td:first-child {
			min-width: 70px;
			padding: 20px 0;
		}
		td:nth-child(2) {
			min-width: 95px;
			padding: 20px 0;
		}
		td:nth-child(3) {
			min-width: 85px;
			padding: 20px 0;
		}
	}
	.more-info {
		font-size: 25px;
		margin-top: -5px;
	}
} 
.menu-items {
	tbody tr {
		cursor: pointer;
		&:hover {
			opacity: 0.75;
		}
		td:first-child {
			width: 40px;
		}
		td:nth-child(2) {
			width: 200px;
			text-align: left;
		}
	}
	th:nth-child(2) div {
		transform: rotate(0deg);
	}
}
.menu-items.mobile input[type=checkbox] {
    margin-top: 3px;
}
.more-info {
	color: #b5b6b7;
	float: right;
	height: 24px;
	.fas {
		font-size: 16px;
	}
}
.accordion {
    padding-top: 20px;
}
.menu-select {
	color: grey;
	i {
		height: 20px;
		width: 20px;
		font-size: 20px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		-o-transition: transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
	
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
	}
	&.selected {
		color: darkred;
		i {
			-webkit-transform: rotate(-135deg);
			-ms-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
}

a.more-info:hover {
    color: #b5b6b7;
}
.no-script 
.noscript {
	background-color: #fff;
	.content {
		text-align: center;
		color: red;
		height: 400px;
		padding: 60px 20px;
	}
	.nav {
		padding: 20px 0;
	}
	footer {
		margin-top: 40px;
	}
}
.back-to-top {
    text-align: center;
    font-size: 14px;
	margin: 20px 0 40px 0;
}
.menu-break {
	div {
		width: 50%;
		display: inline-block;
	}
	.back-to-top {
		text-align: right;
		button {
			cursor: pointer;
			width: 17px;
		}
	}
}

.btn-primary {
	background-color: $primary-grey;
	color: #000;
	&:hover {
		background-color: #d6d6d6;
	}
}
.btn:disabled,
.btn-small:disabled {
	color: #909090;
}
.btn-alt {
	background-color: $primary-blue;
	color: #fff;
	&:disabled {
		background-color: $primary-grey;
		color: #909090;
	}
}
.btn-red {
	background-color: $error-red;
	color: #fff;
	&:hover {
		background-color: #c70000;
	}
}

.contains-marker-container {
	line-height: 0px;
}
span.contains-marker {
	height: 16px;
	width: 16px;
	line-height: 16px;
    border-radius: 10px;
	color: #fff;
	position: relative;
	text-align: center;
	display: inline-block;
	margin-right: 3px;
	margin-top: 3px;
	&:after {
		font-size: 8px;
		line-height: 8px;
		height: 16px;
		width: 16px;
		left: 0;
		right: 0;
		top: 4px;
		position: absolute;
	}
}
span.contains-wheat {
	background-color: #d1af04;
	&:after {
		content: "W";
	}
}
span.contains-egg {
	background-color: #538CC0;
	&:after {
		content: "E";
	}
}
span.contains-milk {
	background-color: #7553C0;
	&:after {
		content: "M";
	}
}
span.contains-tree {
	background-color: #53C071;
	&:after {
		content: "T";
	}
}
span.contains-soy {
	background-color: #C05353;
	&:after {
		content: "S";
	}
}
span.contains-shellfish {
	background-color: #FA7DE5;
	&:after {
		content: "SF";
	}
}
span.contains-fish {
	background-color: #ff7b00;
	&:after {
		content: "F";
	}
}
.modal-bg {
	position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,0.5);
	z-index: 1;
	padding: 0 20px;
	min-width: 320px;
	.modal-container {
		background-color: #fff;
		min-height: 300px;
		max-width: 768px;
		width: 100%;
		border-radius: 50px;
		color: #000;
		position: relative;
		padding: 50px 40px;
	}
}
form {
	input, textarea {
		display: block;
		margin: 10px 1px;
		padding: 5px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 400;
		line-height: 1.5;
		width: 100%;
    	max-width: 300px;
	}
	textarea {
		height: 200px;
    	max-width: 600px;
	}
	&.inline {
		display: inline-block;
		>* {
			display: inline-block;
		}
		button {
			font-size: 1rem;
			line-height: 1;
			width: 30px;
			color: #fff;
			position: relative;
			top: 0;
		}
	}
	&.name-edit {
		input {
			font-size: 28px;
		}
		button {
			line-height: 1;
			flex: 1;
		}
	}
}
.forgot-password {
	max-width: 300px;
	margin: 0 auto;
	button {
		position: relative;
		top: -15px;
		font-size: 0.6rem;
		color: $primary-blue;
		&:hover {
			text-decoration: underline;
		}
	}
}
.login-page {
	display: flex;
	text-align: center;
	margin-top: 30px;
	.account-create {
		flex: 1;
		padding-right: 40px;
		border-right-width: 2px;
		border-right-style: solid;
		border-image: 
			linear-gradient(
			to bottom, 
			rgba(255, 255, 255, 0),
			$primary-blue, 
			rgba(255, 255, 255, 0)
			) 1 100%;
	}
	.account-login {
		flex: 1;
		padding-left: 40px;
	}
	input {
		margin: 10px auto;
	}
}
.vertical-center {
	display: flex;
	flex-direction: column;
}
.flex {
	display: flex;
}
.reset-pw {
	text-align: left;
	input {
		margin: 10px 0;
	}
}
.blue-text {
	color: $primary-blue;
}
.red-text {
	color: $error-red;
}
.account-avatar {
	max-height: 35px;
	max-width: 35px;
	border-radius: 35px;
}
.flex-col-center {
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-status {
	text-align: center;
	margin-top: 40px;
	.icon {
		font-size: 42px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}
.right {
	text-align: right;
}
.center {
	text-align: center;
	justify-content: center;
}
.title-icon {
	font-size: 22px;
    top: -3px;
    color: rgba(255, 255, 255, 0);
	position: relative;
	&:hover {
		color: #bababa;
	}
	@media (max-width: 992px) {
		color: #bababa;
		padding-left: 5px;
	}
}
.mb-0 {
	margin-bottom: 0;
}
.mt-2 {
	margin-top: 2px;
}
.mb-5 {
	margin-bottom: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.ml-10 {
	margin-left: 10px;
}
.mr-10 {
	margin-right: 10px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mt-40 {
	margin-top: 40px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}
.p-0 {
	padding: 0;
	border: 0;
	line-height: 0;
}
.width-25 {
	min-width: 25%;
}
.right {
	text-align: right;
}
.account-options {
	position: relative;
	margin-left: 20px;
	height: $nav-height;
	&:hover .account-options-links {
		display: block;
	}
	.account-options-links {
		position: absolute;
		top: $nav-height;
		background-color: #fff;
		width: 100%;
		padding: 10px;
		min-width: 150px;
		right: 0;
		border: 1px solid #000;
		margin: 0;
		margin-top:-1px;
		display: none;
		list-style-type: none;
		a, a:link, a:visited, a:active, button {
			color: #000;
			font-size: 1rem;
			line-height: 1.8;
			transition: color 0.4s;
		}
		a:hover, button:hover, :hover {
			color: $primary-blue;
		}
	}
	.account-toggle {
		color: #fff;
		font-size: 30px;
		transition: color 0.35s;
	}
}
.down-arrow:after {
	content: '';
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 7px solid #fff;
	margin-left: 10px;
	font-size: 2px;
	pointer-events: none;
	position: absolute;
	top: 50%;
	margin-top: -3px;
}
.up-arrow:after {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
    top: 7px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 7px solid #fff;
	margin-left: 10px;
	pointer-events: none;
}
#spinner {
	text-align: center;
	.loading-spinner {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		div {
			animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			transform-origin: 40px 40px;
			&:after {
				content: " ";
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: $primary-blue;
				margin: -4px 0 0 -4px;
			}
			&:nth-child(1) {
				animation-delay: -0.036s;
				&:after {
					top: 63px;
					left: 63px;
				}
			}
			&:nth-child(2) {
				animation-delay: -0.072s;
				&:after {
					top: 68px;
					left: 56px;
				}
			}
			&:nth-child(3) {
				animation-delay: -0.108s;
				&:after {
					top: 71px;
					left: 48px;
				}
			}
			&:nth-child(4) {
				animation-delay: -0.144s;
				&:after {
					top: 72px;
					left: 40px;
				}
			}
			&:nth-child(5) {
				animation-delay: -0.18s;
				&:after {
					top: 71px;
					left: 32px;
				}
			}
			&:nth-child(6) {
				animation-delay: -0.216s;
				&:after {
					top: 68px;
					left: 24px;
				}
			}
			&:nth-child(7) {
				animation-delay: -0.252s;
				&:after {
					top: 63px;
					left: 17px;
				}
			}
			&:nth-child(8) {
				animation-delay: -0.288s;
				&:after {
					top: 56px;
					left: 12px;
				}
			}
		}
	}
}
@keyframes loading-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.notification {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 20px 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: opacity 3s;
	z-index: 999;
	p {
		margin: 0;
	}
	a,
	a:active,
	a:visited {
		color: #fff;
	}
	a:hover {
		color: $primary-grey;
	}
	&.saved {
		background-color: $primary-blue;
	}
	&.error {
		background-color: $error-red;
	}
	&.static {
		position: static;
	}
}

/* IE10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.nav {
		.date {
			&:after {
				display: none;
			}
			select {
				color: #fff;
				padding: 10px 5px;
			}
		}
		.title {
			padding: 20px 0px;
		}
	}
	.menu-options>button {
		padding: 0!important;
	}
}
/* IE Edge 12+ */ 
@supports (-ms-accelerator:true) {
	.nav {
		.title {
			padding: 20px 0px;
		}
		.date {
			&:after {
				display: none;
			}
			select {
				color: #000;
				padding: 10px 5px;
			}
		}
	}
	.menu-options>button {
		padding: 0!important;
	}
}
@media screen and (max-width: 1010px) {	
	.container-padding	{
		padding: 0 20px;
	}
	footer .content-container {
		padding: 40px 20px 100px 20px;
	}
}
@media screen and (max-width: 992px) {
	.allergies {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	span.contains-marker {
		margin-top: 0;
	}
	.mobile-item-name {
		.contains-marker-container {
			display: inline-block;
			position: relative;
			top: 3px;
		}
		.name {
			margin-right: 3px;
		}
	}
	table.mobile tr td:nth-child(2)>div:first-child {
		padding-right: 20px;
	}
	.menu-items tbody tr:hover {
		opacity: 1;
	}
	h1 {
		font-size: 26px;
	}
	h2 {
		font-size: 22px;
	}
	.back-to-top {
		text-align: right;
		margin: 0;
		button {
			color: #fff;
		}
	}
	.nav .container-padding .date {
		padding-left: 15px;
	}
	.no-script .content {
		height: 300px;
		padding: 40px 20px;
	}
	.remove-margin {
		margin-right: -20px;
		margin-left: -20px;
	}
	.total-nutrition {
		tr {
			td:first-child,
			td:nth-child(2) {
				min-width: 60px;
				padding: 20px 0;
			}
			td:nth-child(3) {
				min-width: 85px;
				padding: 20px 0;
			}
		}
	}
}
@media screen and (min-width: 769px) {
	.mobile-only {
		display: none;
	}
}
@media screen and (max-width: 768px) {
	.hidden-on-mobile {
		display: none;
	}
	.login-page {
		display: block;
		.account-create {
			padding-right: 0;
			border-right-width: 0;
			border-image: none;
		}
		.account-login {
			padding-left: 0;
		}
	}
}
@media screen and (max-width: 600px) {
	.menu-options {
		&>button:hover {
			text-decoration: none;
		}
		&>button {
			flex: 0 0 50%;
			word-break: break-word;
			padding: 0;
		}
		&.open {
			margin: 0 -20px 40px -20px;
		}
	}
	.nav .date {
		margin-left: 0;
		select {
			padding: 20px 10px 20px 10px;
		}
	}
	.account-options {
		margin-left: 15px;
	}
	.hide-on-mobile {
		display: none;
	}
	.table.saved-meals-table .meal-options {
		width: 64px;
	}
}
@media screen and (max-width: 400px) {
	.nav {
		font-size: 12px;
	}
	#delete-conf-content {
		.btn-alt {
			margin-left: 0;
			margin-top: 5px;
			display: block;
		}
	}
}
/* @media screen and (max-width: 320px) {
	.total-nutrition {
		tr td:first-child {
			width: 90px;
		}
		td {
			font-size:100%;
		}
	}
	table.mobile tr td:nth-child(2) {
		padding-left: 0;
	}
	.mobile .more-info {
		padding: 2px 0 5px 5px;
	}
	.menu-options>button {
		word-wrap: break-word;
		flex: 0 0 100%;
	}
	.nav {
		.container-padding {
			display: block;
			padding: 20px;
			text-align: center;
		}
		.title {
			font-size: 120%;
			text-align: center;
		}
		.date {
			text-align: center;
			margin-top: 10px;
			padding-left: 0;
			&:after {
				margin-top: 8px;
			}
			select {
				color: #fff;
				padding: 10px 0 0 0;
				width: 100%;
			}
		}
	}
} */